import React from 'react';

import Layout from '../../../components/Layout.jsx';
import SEO from '../../../components/Seo.jsx';

const Index = () => (
  <Layout lang="fr">
    <SEO
      title="Conditions Générales | Horair.es"
      lang="fr"
      description="Vos horaires 7 jours sur 7 faciles"
      keywords={['horaire', 'planning', '24 / 7', '']}
    />
    <h1>Conditions générales</h1>

    <h2>Champ d'application</h2>

    <p>
      Les présentes conditions générales (CG) règlent les relations entre la société QuaroTech SARL
      (ci-après appelée « QuaroTech ») et ses clientes et clients (ci-après appelés « le client »).
    </p>
    <p>
      Elles font partie intégrante du contrat passé entre le client et QuaroTech et couvrant tout
      produit ou service. Font aussi partie dudit contrat les descriptifs de prestations et de
      fonctionnalités des services et produits.
    </p>
    <p>
      Toute modification apportée aux présentes conditions générales doit revêtir la forme écrite.
    </p>

    <h2>Prestations de QuaroTech</h2>

    <p>
      QuaroTech offre au client des produits et des services dans le domaine des technologies de
      l'information et de la communication sur l'Internet concernant la santé. Elle s'engage à
      s'employer au mieux de ses connaissances et de ses capacités à fournir des prestations de
      qualité.
    </p>
    <p>
      Le contenu et l'entendue des différentes prestations sont définis dans les conventions de
      prestations, qui, avec le contrat et les présentes Conditions générales, définissent les
      relations contractuelles entre le client et QuaroTech.
    </p>

    <h2>Prestations des clients </h2>

    <h3>Prix </h3>

    <p>
      Les prix dus par les clients pour les services et les produits sont fixés dans le contrat ou
      le barème de prix applicable. Ils n'incluent pas la taxe sur la valeur ajoutée, à moins que le
      contrat n'en dispose autrement.
    </p>

    <h3>Responsabilité du client </h3>

    <p>
      Le client veille à ce que les services et les produits pour lesquels il a conclu un contrat
      avec QuaroTech soient utilisés conformément aux dispositions légales et contractuelles.
    </p>
    <p>
      Les conventions de prestations peuvent en outre prévoir des obligations d'assistance et de
      coopération.
    </p>

    <h3>Facturation </h3>

    <p>
      QuaroTech facture au client les prestations convenues conformément aux descriptifs de
      prestations.
    </p>

    <h3>Conditions de paiement </h3>

    <p>
      Les factures doivent être réglées au plus tard à la date d'exigibilité indiquée. Jusqu'à cette
      date, le client peut contester sa facture par écrit avec indication des motifs, faute de quoi
      la facture est considérée comme acceptée.
    </p>
    <p>
      QuaroTech pourra prendre les mesures nécessaires afin d'empêcher toute aggravation du dommage
      lorsque le client n'a pas payé une facture à la date d'exigibilité ou ne l'a pas contestée
      avec indication des motifs. QuaroTech pourra résilier le contrat sans préavis ni dédommagement
      lorsque le client n'a pas payé sa facture dans un délai de trente jours à compter de la mise
      en œuvre des mesures. Les frais occasionnés à QuaroTech par le retard de paiement sont à la
      charge du client.
    </p>

    <h2>Responsabilité de QuaroTech</h2>

    <p>
      QuaroTech répond envers les clients de la fourniture soigneuse et conforme au contrat de ses
      prestations. Toute garantie éventuelle découle des descriptifs de prestations.
    </p>
    <p>
      QuaroTech décline toute responsabilité en cas de perturbation ou d'interruption du service.
      Dans le cadre de la législation en vigueur, QuaroTech décline également toute responsabilité
      pour des dommages directs ou indirects, pour elle-même comme pour les tiers qu'elle pourrait
      mandater. QuaroTech ne pourra en aucun cas être tenue responsable de dommages subséquents, de
      pertes de données, de prétentions de tiers ou de manques à gagner.
    </p>
    <p>
      Sont réservées les éventuelles dispositions sur la responsabilité figurant dans les
      descriptifs de prestations.
    </p>

    <h2>Dispositions particulières </h2>

    <h3>Propriété intellectuelle </h3>

    <p>
      Pendant la durée du contrat, le client a le droit non transférable et non exclusif d'utiliser
      les services et les produits conformément aux descriptifs de prestations.
    </p>
    <p>
      QuaroTech ou les tiers autorisés conservent tous les droits de propriété intellectuelle sur
      les services et les produits de QuaroTech. Pour les droits appartenant à des tiers, QuaroTech
      garantit qu'elle dispose des droits d'utilisation et de commercialisation requis.
    </p>
    <p>Les descriptifs de prestations contiennent les détails relatifs aux droits d'utilisation.</p>

    <h3>Force majeure </h3>

    <p>
      Si l'une des parties ne peut pas, malgré tous ses efforts, remplir ses obligations
      contractuelles en raison d'un cas de force majeure comme phénomènes naturels d'une intensité
      particulière, conflits, grèves, restrictions officielles imprévues, etc., l'exécution du
      contrat sera reportée en fonction de la nature et de l'ampleur de l'évènement ou intégralement
      annulée.
    </p>

    <h2>Entrée en vigueur, durée et résiliation du contrat </h2>
    <h3>Entrée en vigueur </h3>

    <p>
      Le contrat court jusqu'à exécution complète de la commande ou conformément aux accords
      figurant dans le contrat. L'une ou l'autre partie peut le résilier en tout temps par écrit,
      moyennant un préavis de trente jours, sauf dispositions contraires du contrat ou des
      descriptifs de prestations.
    </p>
    <p>
      Le client résiliant avant son échéance un contrat conclu pour une durée minimale doit régler à
      QuaroTech les sommes dues au titre de la période restant à courir.
    </p>

    <h3>Modifications du contrat </h3>

    <p>
      QuaroTech se réserve le droit de modifier les présentes CG ainsi que ses barèmes de prix. Dans
      ce cas, elle communiquera au client les modifications dans un délai permettant à ce dernier de
      résilier son contrat dans le respect du préavis convenu. En l'absence de résiliation notifiée
      par écrit dans ce délai, les modifications seront considérées comme acceptées par le client.
    </p>
    <p>
      Toute modification apportée aux autres parties du contrat doit être communiquée par écrit et
      signée par les deux parties.
    </p>

    <h2>Clause de sauvegarde </h2>

    <p>
      Au cas où l'une ou plusieurs des dispositions des présentes CG deviendraient caducs ou
      irréalisables, toutes les autres dispositions resteront valables.
    </p>
    <p>
      Les dispositions caduques ou irréalisables seront remplacées par une disposition conforme au
      but de la convention ou au moins proche, et susceptible d'avoir été adoptée par les parties
      s'ils avaient eu connaissance de la nullité ou du caractère irréalisable des dispositions
      concernées. Il en va de même pour toute éventuelle lacune constatée dans les présentes CG.
    </p>

    <h2>Dispositions finales</h2>

    <p>Les présentes CG sont soumises au droit suisse.</p>
    <p>
      Pour tous les litiges portant sur les présentes CG, le contrat auquel elles se rapportent et
      les autres pièces liées au contrat, le for est le siège de QuaroTech.
    </p>
    <p>
      QuaroTech peut intenter une action contre le client au siège ou au domicile de ce dernier.
    </p>

    <p>
      QuaroTech SARL, <i>mars 2019</i>
    </p>
  </Layout>
);

export default Index;
